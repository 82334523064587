/* src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: white;
}

.App {
  text-align: center;
}

/* Style for WhatsApp icon */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366; /* WhatsApp green color */
  color: white;
  z-index: 1000; /* Ensure it stays on top of other elements */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex; /* Center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  cursor: pointer;
}

.whatsapp-icon:hover {
  background-color: #1ebe4d; /* Slightly darker green on hover */
}
